



















































import { Component, Vue } from 'vue-property-decorator';

import ErrorKuspitAccountViewModel
  from '@/vue-app/view-models/components/contract-savings/creating-account/error-kuspit-account-view-model';

@Component({
  name: 'ErrorKuspitAccount',
  components: {
    KuspitAccountLinkingModal: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingModal.vue'),
  },
})
export default class ErrorKuspitAccount extends Vue {
  error_kuspit_account_view_model = Vue.observable(
    new ErrorKuspitAccountViewModel(),
  );
}
