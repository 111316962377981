import i18n from '@/vue-app/plugins/i18n';

export default class ErrorKuspitAccountViewModel {
  readonly i18n_namespace = 'components.contract-savings.creating_account.kuspit.error';

  show_kuspit_link_modal = false;

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  goToDashboard = () => {
    window.location.href = '/';
  }

  showKuspitLinkModal = () => {
    this.show_kuspit_link_modal = true;
  }
}
