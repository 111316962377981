




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import ErrorKuspitAccount
  from '@/vue-app/components/contract-savings/creating-account/ErrorKuspitAccount.vue';
import ContractTimeout from '@/vue-app/components/contracts/timeout/ContractTimeout.vue';
import ContractSavingsCreatingAccountKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/creating-account/contract-savings-creating-account-kuspit-view-model';

@Component({
  name: 'ContractSavingsKuspitAccount',
  components: { ErrorKuspitAccount, ContractTimeout },
})
export default class ContractSavingsKuspitAccount extends Vue {
  view_model = Vue.observable(
    new ContractSavingsCreatingAccountKuspitViewModel(),
  );

  @Watch('view_model.status.percent')
  onPercentChange(percent: number) {
    this.$emit('loadingInfo', percent < 100);
  }

  @Watch('view_model.status.has_problem')
  onStatusHasProblemChange() {
    this.$emit('loadingInfo', false);
  }

  nextStep() {
    this.$emit('nextStep');
  }

  canCancelProcess() {
    this.$emit('endProcess');
  }

  created() {
    this.$emit('loadingInfo', this.view_model.status.percent < 100);
    this.view_model.initialize();
  }
}
